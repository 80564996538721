import Lenis from 'lenis';

export default class LenisScroll {
  constructor() {
    this.init();
  }

  init() {
    // Select all elements with the class 'panel__content'
    const scrollablePanels = document.querySelectorAll('.panel__content');

    // Initialize Lenis for each '.panel__content' individually
    scrollablePanels.forEach((panel) => {
      const lenis = new Lenis({
        smooth: true,
        lerp: 0.2,
        // Enable `wrapper` mode for element-specific scrolling
        wrapper: panel,
      });

      panel.addEventListener('wheel', (event) => {
        lenis.raf(event.timeStamp);
        event.preventDefault(); // Prevent default scrolling behavior on the entire page
      });

      // Start animation loop for each Lenis instance
      function raf(time) {
        lenis.raf(time);
        requestAnimationFrame(raf);
      }
      requestAnimationFrame(raf);
    });
  }
}
