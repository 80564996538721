export default class URLHandler {
  init() {
    document.addEventListener('click', (event) => {
      const target = event.target.closest('a[href^="#"]');
      if (target) {
        event.preventDefault();
        const targetElement = document.getElementById(
          target.getAttribute('href').substring(1)
        );
        targetElement && targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }
}
