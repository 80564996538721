export default class Loader {
  constructor(loaderSelector, counterSelector, experience) {
    this.loader = document.querySelector(loaderSelector);
    this.counter = document.querySelector(counterSelector);
    this.experience = experience;
  }

  init() {
    window.addEventListener('load', () => {
      const startTime = Date.now();
      this.updateCounter(startTime);
    });
  }

  updateCounter(startTime) {
    const update = () => {
      const loadPercentage = Math.min(
        100,
        Math.floor((Date.now() - startTime) / 10)
      );
      this.counter.textContent = String(loadPercentage).padStart(3, '0');

      loadPercentage < 100 ? requestAnimationFrame(update) : this.hideLoader();
    };
    update();
  }

  hideLoader() {
    setTimeout(() => {
      this.loader.style.opacity = '0';

      setTimeout(() => {
        this.loader.style.display = 'none';
        document.body.style.overflow = 'auto';
      }, 1000);

      this.experience.startAnimations();
    }, 500);
  }
}
