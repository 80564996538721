export default class AnimationHandler {
  constructor() {
    this.typingInterval = null;
  }

  init() {
    const pageProjets = document.querySelector('.projets');
    const projetsSubTitle = pageProjets.querySelector('.subtitle');
    const textSubTitle = projetsSubTitle.textContent;
    const projets = document.querySelectorAll('.projets .projet__list');

    const typeEffect = (text) => {
      clearInterval(this.typingInterval);
      let index = 0;
      projetsSubTitle.textContent = '';
      this.typingInterval = setInterval(() => {
        projetsSubTitle.textContent += text[index++];
        if (index >= text.length) clearInterval(this.typingInterval);
      }, 10);
    };

    const updateSubTitle = () => {
      const activeProject = Array.from(projets).find((projet) =>
        projet.classList.contains('is-active')
      );
      typeEffect(
        activeProject
          ? activeProject.getAttribute('data-sub-title')
          : textSubTitle
      );
    };

    projets.forEach((projet) => {
      new MutationObserver((mutations) => {
        if (
          mutations.some(
            (mutation) =>
              mutation.type === 'attributes' &&
              mutation.attributeName === 'class'
          )
        ) {
          updateSubTitle();
        }
      }).observe(projet, { attributes: true });
    });

    updateSubTitle();
  }
}
