import Carousel from '../components/Carousel.js';
import Scrolly from '../components/Scrolly.js';
import LenisScroll from '../components/LenisScroll.js';

export default class ComponentFactory {
  constructor() {
    this.componentList = {
      Carousel,
      Scrolly,
      LenisScroll,
    };

    this.init();
  }

  init() {
    const components = document.querySelectorAll('[data-component]');

    for (let i = 0; i < components.length; i++) {
      const element = components[i];
      const componentName = element.dataset.component;

      if (this.componentList[componentName]) {
        new this.componentList[componentName](element);
        // console.log('New component:', componentName);
      } else {
        // console.log('Component not found:', componentName);
      }
    }
  }
}
