import ComponentFactory from './utils/ComponentFactory';
import Experience from './utils/Experience';
import Icons from './utils/Icons';

import AnimationHandler from './utils/AnimationHandler';
import Loader from './utils/Loader';
import URLHandler from './utils/URLHandler';

class Main {
  constructor() {
    Icons.load();
    this.experience = new Experience();
    new ComponentFactory();

    this.loader = new Loader('.loader', '.counter', this.experience);
    this.urlHandler = new URLHandler();
    this.animationHandler = new AnimationHandler();

    this.init();
  }

  init() {
    document.documentElement.classList.add('has-js');
    this.loader.init();
    this.urlHandler.init();
    this.animationHandler.init();
  }
}

new Main();
